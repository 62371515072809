const initialState = {
    contractorDDList: [],
    loadingDDList:[]
}

const contractorReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CONTRACTOR_DD_LIST':
            return {...state, contractorDDList: action.payload}
        case 'LOADING_DD_LIST':
                return {...state, loadingDDList: action.payload}
        default:
            return state
    }
}

export default contractorReducer
