import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {store, persistor} from './redux/store';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import ability from './configs/acl/ability';
import {AbilityContext} from './utility/context/Can';
import Spinner from "./components/spinner/spinner";
import './index.scss';
import './styles/index.css'
// import './assets/font/styles.css'
// ** Lazy load app
const LazyApp = lazy(() => import('./Root'))

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Suspense fallback={
                <div style={{ height: '680px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner />
                </div>
            }>
                <AbilityContext.Provider value={ability}>
                    <BrowserRouter basename={`/`}>
                        <LazyApp/>
                    </BrowserRouter>
                </AbilityContext.Provider>
            </Suspense>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();