const initialState = {
    zoneTypeWithScopes: [],
    isUpdatingZoneCol:false,
    editableZonesList:[],
    editZoneSearchText:'',
    isFetchingEditableZones:false,
    loadingZoneImgRotation:false
}

const zonesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ZONE_TYPE_AND_SCOPE':
            return {...state, zoneTypeWithScopes: action.payload}
        case 'IS_UPDATING_ZONE_COL':
            return {...state, isUpdatingZoneCol: action.payload}
        case 'SET_EDITABLE_ZONES':
            return {...state, editableZonesList: action.payload}
        case 'SET_EDITABLE_SEARCH_TEXT':
            return {...state, editZoneSearchText: action.payload}
        case 'SET_FETCH_EDITABLE_ZONES':
            return { ...state, isFetchingEditableZones: action.payload }
        case 'LOADING_ZONE_IMG_ROTATION':
           return { ...state,loadingZoneImgRotation: action.payload }
        default:
            return state
    }
}

export default zonesReducer
