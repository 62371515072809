  const initialState = {
    fetchAreaUnit: [],
    surfaceTypes : [],
    fetchTermsConditions:[],
    additionalInfo:[],
    loadingAreaUnits: false,
    loadingSurfaceTypes:false,
    loadingTermsConditions:false,
    salesTax:[],
    loadingSalesTax:false,
    salesTaxSearch:'',
  };

  const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_AREA_UNITS":
        return { ...state, fetchAreaUnit: action.payload };
      case "LOADING_AREA_UNITS":
        return { ...state, loadingAreaUnits: action.payload };
        case "FETCH_TERMS_CONDITIONS":
        return { ...state, fetchTermsConditions: action.payload };
        case "LOADING_TERMS_CONDITIONS":
        return { ...state, loadingTermsConditions: action.payload };
        case "FETCH_SURFACE_TYPES":
          return { ...state, surfaceTypes: action.payload };
        case "LOADING_SURFACE_TYPES":
        return { ...state, loadingSurfaceTypes: action.payload };
        case "FETCH_SALES_TAX":
          return { ...state, salesTax: action.payload };
        case "LOADING_SALES_TAX":
        return { ...state, loadingSalesTax: action.payload };
        case 'SALES_TAX_SEARCH':
          return {...state, salesTaxSearch: action.payload}
        case "FETCH_ADDITIONAL_INFO":
          return { ...state, additionalInfo: action.payload };
        case "LOADING_ADDITIONAL_INFO":
        return { ...state, loadingAdditionalInfo: action.payload };
      default:
        return state;
    }
  };
  export default settingsReducer;
