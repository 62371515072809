const initialState = {
    woSelectedImages: [],
    lineItems:[],
    groups:["Ungrouped Line Items"],
    proposalCopied:{},
    updateAreaUnit:[],
    proposalList:[],
    loadingList:false,
    pdfModalStatus:false,
    pdfModalId:0,
    clientProposalList:[],
    loadingClientList:false

  };
  
  const proposalReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_WO_SELECTED_IMAGES':
        return { ...state, woSelectedImages: action.payload };
        case 'ADD_REMOVE_LINE_ITEMS':
          return { ...state, lineItems: action.payload };
          case 'COPY_ADD_INFO':
            return { ...state, proposalCopied: action.payload };
            case 'UPDATE_AREA_UNIT':
              return {...state,updateAreaUnit:action.payload}
            case 'Add_UPDATE_GROUPS':
              return {...state,groups:action.payload}
            case 'LIST_PROPOSAL':
              return {...state,proposalList:action.payload}
            case 'CLIENT_PROPOSAL_LIST':
              return {...state,clientProposalList:action.payload}
            case 'LOADING_PROPOSAL_LIST':
              return {...state,loadingList:action.payload}
            case 'LOADING_CLIENT_PROPOSAL_LIST':
              return {...state,loadingClientList:action.payload}
            case 'SET_PDF_MODAL_ID':
              return {...state,pdfModalId:action.payload}
        default:
        return state;
    }
  };
  

  export default proposalReducer;
