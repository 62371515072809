import React, { Fragment } from 'react';
import './spinner.css'

const Spinner = () => {
    return (
        <Fragment>
            <div className="loader-box">
                <div className="loader-8"> </div>
            </div>
        </Fragment >
    )
}

export default Spinner;