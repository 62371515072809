const initialState = {
  drpInternalUser: [],
  loadingInternalUserDRP:false,
  sitesByUsers:[],
  loadingUserList:false,
  accountUserDRP:[],
  loadingAccountUserDRP:false,
  campaignsByUsers:[],
  loadingUserCampaignList:false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INTERNAL_USER_DRP':
      return { ...state, drpInternalUser: action.payload };
    case 'LOADING_INTERNAL_USERS_DRP':
      return { ...state, loadingInternalUserDRP: action.payload };  
   case 'SITES_CREATED_BY_USER':
    return {...state, sitesByUsers: action.payload}
    case 'LOADING_USER_LIST':
    return {...state, loadingUserList: action.payload}
     case 'CAMPAIGNS_CREATED_BY_USER':
    return {...state, campaignsByUsers: action.payload}
    case 'LOADING_USER_CAMPAIGN_LIST':
    return {...state, loadingUserCampaignList: action.payload}
    case 'SET_ACCOUNT_USER_DRP':
      return { ...state, accountUserDRP: action.payload };
    case 'LOADING_ACCOUNT_USER_DRP':
      return { ...state, loadingAccountUserDRP: action.payload };  
    default:
      return state;
  }
};

export default userReducer;
