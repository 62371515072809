import { LOGIN, LOGOUT } from './actionTypes'

const initialState = {
    userData: {},
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                userData: action.data,
                [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
                [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
            }
        case 'LOGOUT':
            return { ...state, userData: {}}
        default:
            return state
    }
}

export default authReducer;