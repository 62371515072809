const initialState = {
    campaignList: []
}

const campaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CAMPAIGNS':
            return {...state, campaignList: action.payload}
        case 'SET_ASSESSMENT_DETAIL':
            return {
                ...state,
                assessment_detail: action.payload
                
            } 
        default:
            return state
    }
}

export default campaignReducer
