
const initialState = {
  inventroyReport: [],
  oneYearReport: [],
  fiveYearReport: [],
  loadingMaintenencePlan: false,
};

const MaintenencePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INVENTORY_REPORT':
      return { ...state, inventroyReport: action.payload };
      case 'SET_ONE_YEAR_REPORT':
        return { ...state, oneYearReport: action.payload };
      case 'SET_FIVE_YEAR_REPORT':
        return { ...state, fiveYearReport: action.payload };
    case "LOADING_MAINTENECE_PLAN":
      return { ...state, loadingMaintenencePlan: action.payload };
    default:
      return state;
  }
};
export default MaintenencePlanReducer;
