import { Ability } from '@casl/ability';
import { initialAbility } from './initialAbility';

const userData = JSON.parse(localStorage.getItem('userData'))
// console.log(userData ,'userData')
const existingAbility = userData ? userData.ability : null
// console.log(existingAbility ,'existingAbility')
export default new Ability(existingAbility || initialAbility)


// import { defineAbility } from '@casl/ability';
// import { initialAbility } from './initialAbility'
// const userData = JSON.parse(localStorage.getItem('userData'))
//     var existingAbility = userData ? userData.ability : null
//     existingAbility = existingAbility || initialAbility
// export default defineAbility((can, cannot) => {
//
//     // can("manage", 'account')
//     existingAbility.forEach(element => {
//         // can(element['action'], element['subject']);
//     });
// });
